.p-galleria {
  & .p-galleria-indicators {
    & .p-galleria-indicator {
      &.p-highlight {
        & button {
          background-color: #27a37e !important;
        }
      }
    }
  }
}
