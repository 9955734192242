/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/themes/viva-dark/theme.css";
@import "primeng/resources/primeng.css";
@import "./assets/styles/p-galleria.scss";

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: rgb(39, 163, 126);
  background: -moz-linear-gradient(
    90deg,
    rgba(39, 163, 126, 1) 0%,
    rgba(13, 12, 18, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(39, 163, 126, 1) 0%,
    rgba(13, 12, 18, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(39, 163, 126, 1) 0%,
    rgba(13, 12, 18, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#27a37e",endColorstr="#0d0c12",GradientType=1);
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 20%;

  @media (max-width: 768px) {
    margin: 0 5%;
  }
}

a {
  color: white;
  text-decoration: underline;
}
